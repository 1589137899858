import { ThemeObject } from "../interface";
import { statics } from "./StaticsComponents";


export const tandem: ThemeObject = {
  color: {
    navBar: "#f4f6f8",
    navBarIcon: "#37303E",
    navBarTxt: "#ffff",
    sideBar: "#f4f6f8",
    txt: '#37303E',
    txtDark: '#37303E',
    txtLigth: '#37303e99',
    bkg: "#f4f6f8",
    bkgLight: "#FAF9F9",
    btnPrimary: '#85191a',
    btnTextPrimary: '#ffff',
    btnSecondary: '#7e9ab7',
    error: '#d32f2f',
    btnGreen: '#19a119',
    border: '#0000001f',
    white: '#ffff',
    item: '#45526E',
    itemSelectedText: '#85191a',
    itemSelected: '#85191a33',
    link: '#85191a',
    linkHover: '#85191a0f',
  },
  ...statics
}