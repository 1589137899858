import {
  BM_API,
  BM_API_URL,
  BM_API_VERSION,
  getAccessToken,
  instance
} from 'src/services/utils';

interface Props {
  activeBot: string | number | undefined;
  next?: string | null;
  filterSelected: string | null;
  search?: string;
}

export const getCampaignsService = async ({
  activeBot,
  next = null,
  filterSelected,
  search
}: Props) => {
  const baseURL =
    next !== null
      ? next
      : `${BM_API_URL}/${BM_API}/${BM_API_VERSION}/campaigns/${activeBot}/infinite/?search=${search}`;
  const response = await instance.get(baseURL, {
    headers: {
      Authorization: `jwt ${getAccessToken()} `
    },
    params: {
      status: filterSelected
    }
  });
  return response;
};

export const postStatus = async (id: any, status: any) => {
  const baseURL = `${BM_API_URL}/${BM_API}/${BM_API_VERSION}/campaigns/${id}/${status}/`;
  const response = await instance.post(
    baseURL,
    {},
    {
      headers: {
        Authorization: `jwt ${getAccessToken()} `
      }
    }
  );
  return response;
};
