import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { hideInternalServerView } from 'src/redux/slices/internalServerSlice';
import store from 'src/redux/store';
import LOGO from '../../assets/logo-inceptia-white.svg';
import CHATTIGO from '../../assets/Chattigo/logo.png';
import PELIKANUS from '../../assets/Pelikanus/pelikanus.svg';
import TANDEM from '../../assets/Tandem/tandem.png';
import { NavBarCard } from './NavBarCard';
import {
  Hr,
  NavbarBox,
  NavbarContainer,
  NavbarLogo,
  NavbarText,
  NavbarWrapper,
} from './NavBarStyles/Navbar.styles';
import { getThemeFromUrl } from 'src/Themes/getThemeFromUrl';

const selectLogo = (theme: string) => {
  switch (theme) {
    case 'inceptia':
      return LOGO;
    case 'chattigo':
      return CHATTIGO;
    case 'pelikanus':
      return PELIKANUS;
    case 'tandem':
      return TANDEM;
    default:
      return LOGO;
  }
};

export const NavBar = () => {
  const { isInternalServerError } = useAppSelector(
    (state: any) => state.internalServerError
  );

  const onClick = () => {
    if (isInternalServerError) {
      store.dispatch(hideInternalServerView());
    }
  };

  return (
    <NavbarContainer>
      <NavbarWrapper>
        <NavbarBox>
          <Link to={'/dashboard'} onClick={onClick} className={'link'}>
            <NavbarLogo
              style={
                getThemeFromUrl() === 'tandem' ? { marginLeft: '2.25em' } : {}
              }
              src={selectLogo(getThemeFromUrl())}
              height={
                getThemeFromUrl() === 'pelikanus'
                  ? '2.8rem'
                  : getThemeFromUrl() === 'tandem'
                  ? '2.3rem' // o el valor que quieras para tandem
                  : '2rem'
              }
            />

            {getThemeFromUrl() === 'inceptia' && (
              <>
                <Hr>
                  {' '}
                  <hr />{' '}
                </Hr>
                <NavbarText> Bot Manager </NavbarText>
              </>
            )}
          </Link>
        </NavbarBox>

        <NavbarBox>
          <NavBarCard />
        </NavbarBox>
      </NavbarWrapper>
    </NavbarContainer>
  );
};
