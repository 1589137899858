import { ThemeObject } from './interface';
import { chattigo } from './Objects/Chattigo';
import { inceptia } from './Objects/Inceptia';
import { inceptiaDark } from './Objects/inceptiaDark';
import { pelikanus } from './Objects/Pelikanus';
import { tandem } from './Objects/Tandem';

export const theme: { [key: string]: ThemeObject } = {
  inceptia: inceptia,
  inceptiaDark: inceptiaDark,
  chattigo: chattigo,
  pelikanus: pelikanus,
  tandem: tandem,
};
